// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	i18nFiles: 'websitelabels/i18n/',
	oldIntranetUrl: 'http://www.naar.local/it/',
	intranetUrl: 'http://localhost:4200/',
    servicesEndpointRoot: 'https://api.naar.com/',
    alternateServicesEndpointRoot: 'https://drawbench2.naar.com/',
    website: 'auto', // 1 'Naar Italia', 2 'Naar France', 3 'Naar B2C Italia', 4 'Naar Germany', 5 'Naar Benelux'
	googleMapsApiKey: 'AIzaSyBSNG0UvbqrSdk0JPejA3_K-eDXTCbpRv4',
	naarPrintServiceUrl: 'http://localhost:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have c negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
