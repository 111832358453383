import {EventEmitter, Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {User} from './user.class';
import {StorageService} from '../storage/storage.service';
import {AppUserOptions, Languages, UiUserOptions} from './localuseroptions.class';
import {ELingua, IChangePasswordRequest, ILoginRequest, ILoginViaEmailRequest, IPasswordResetRequest, ISendLoginViaEmailRequest, ISendPasswordRecoveryRequest, UserDataResponse} from '../backend/naar-api-client';
import {BehaviorSubject, Observable} from 'rxjs';

export enum ECommand {
	CRYPTIC = 'CRYPTIC',
	PLANNER = 'PLANNER',
	CART = 'CART',
	FLIGHTS = 'FLIGHTS',
	RENTALS = 'RENTALS',
	HOTELS = 'HOTELS',
	TRANSFERTS = 'TRANSFERTS',
	VISITS = 'VISITS',
	TOURS = 'TOURS',
	EDIT_HEADER = 'EDIT_HEADR',
	EDIT_AGENCY = 'EDIT_AGENCY',
	TOURS_VISITS = 'TOURS_VISITS',
	GO_TO_TRIP = 'GO_TO_TRIP',
	ONLINE_HELP = 'ONLINE-HELP',
	DEBUG_BAR = 'DEBUG-BAR',
	SHELL = 'SHELL'
}

export interface IHotKeyDef {
	command: ECommand;
	code: string;
	shift: boolean;
	alt: boolean;
	ctrl: boolean;
	meta: boolean;
}

const hotKeyDefs: IHotKeyDef[] = [
	{
		command: ECommand.CRYPTIC,
		code: 'F2',
		shift: false,
		alt: false,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.PLANNER,
		code: 'F3',
		shift: false,
		alt: false,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.CART,
		code: 'F4',
		shift: false,
		alt: false,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.FLIGHTS,
		code: 'KeyF',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.RENTALS,
		code: 'KeyC',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.HOTELS,
		code: 'KeyH',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.TRANSFERTS,
		code: 'KeyX',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.VISITS,
		code: 'KeyV',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.TOURS,
		code: 'KeyT',
		shift: false,
		alt: true,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.EDIT_HEADER,
		code: 'F7',
		shift: false,
		alt: false,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.EDIT_AGENCY,
		code: 'F8',
		shift: false,
		alt: false,
		ctrl: false,
		meta: false
	}, {
		command: ECommand.GO_TO_TRIP,
		code: 'KeyV',
		shift: true,
		alt: true,
		ctrl: false,
		meta: false
	}
];

@Injectable({
	providedIn: 'root',
})
export class UserService {
	#userChanged: EventEmitter<void> = new EventEmitter<void>();
	/**
	 * Osservabile che emette al cambiamento di utente
	 */
	public get userChanged(): Observable<void> {
		return this.#userChanged.asObservable();
	}

	#working: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	/**
	 * Emette true se il servizio sta lavorando in background
	 */
	public get working(): Observable<boolean> {
		return this.#working.asObservable();
	}

	/**
	 * Elenco dei tasti di scelta rapida dell'utente
	 */
	public get hotKeys(): IHotKeyDef[] {
		return hotKeyDefs;
	}

    #mustChangePassword = false;
    /**
     * Riporta true se l'utente deve cambiare la password
     */
    public get mustChangePassword(): boolean {
        return this.#mustChangePassword;
    }

    #resetPasswordCode: string = null;
    /**
     * Il codice per il reset della password, se presente
     */
    public get resetPasswordCode(): string {
        return this.#resetPasswordCode;
    }

	#isLoggedIn = false;
	/**
	 * Riporta true se l'utente è loggato
	 */
	public get isLoggedIn(): boolean {
		return this.#isLoggedIn;
	}

	#currentUser: User = null;
	/**
	 * Utente corrente. Null se non loggato
	 */
	public get currentUser(): User {
		return this.#currentUser;
	}

	#permissions: string[] = [];
	/**
	 * Elenco dei permessi dell'utente
	 */
	public get permissions(): string[] {
		return this.#permissions;
	}

	#canBookFlights = false;
	/**
	 * True se può prenotare i voli
	 */
	public get canBookFlights(): boolean {
		return this.#canBookFlights;
	}

	#canBook = false;
	/**
	 * True se questo utente è abilitato a prenotare i viaggi
	 */
	public get canBook(): boolean {
		return this.#canBook;
	}

	#uiOptions: UiUserOptions = new UiUserOptions();
	/**
	 * Opzioni UI. Presenti sempre, anche con utente non loggato
	 */
	public get uiOptions(): UiUserOptions {
		return this.#uiOptions;
	}

	#appOptions: AppUserOptions = new AppUserOptions();
	/**
	 * Opzioni applicazione. Presenti sempre, anche con utente non loggato
	 */
	public get appOptions(): AppUserOptions {
		return this.#appOptions;
	}

	#currentLang$: BehaviorSubject<Languages> = new BehaviorSubject<Languages>('it');
	/**
	 * Emette la lingua corrente (codice ISO 2 lettere)
	 */
	public get currentLang$(): Observable<Languages> {
		return this.#currentLang$.asObservable();
	}

	constructor(private backend: BackendService, private storage: StorageService) {
	}

	/**
	 * Imposta la lingua per l'utente
	 * @param newLang
	 */
	public async setLang(newLang: Languages) {
		this.uiOptions.lang = newLang;
		await this.writeOptions(this.appOptions, this.uiOptions);
		this.#currentLang$.next(newLang);
	}

	/**
	 * Ritorna true se almeno uno dei permessi in input è presente tra quelli dell'utente
	 * @param permissions Elenco di permessi, almeno uno deve corrispondere. Inserire * come primo ed unico elemento per indicare "qualsiasi permesso" purché loggato
	 */
	public can(permissions: string[]): boolean {
		if (!permissions || !this.isLoggedIn) {
			return false;
		}
		if (permissions.length === 1 && permissions[0] === '*') {
			return true;
		}
		let found = false;
		permissions.forEach((p) => {
			if (!found) {
				found = this.permissions.indexOf(p) >= 0;
			}
		});
		return found;
	}

	/**
	 * Costruisce le opzioni utente UI e App
	 * @param response Response opzionale della chiamata di login o di check
	 * @private
	 */
	private getOptions(response?: any) {
		this.#uiOptions = response?.opzioniUI;

		// Se non ci sono opzioni provo a caricarle dallo storage
		if (!this.#uiOptions) {
			// Di default la lingua della UI è quella del browser
			let browserLang = navigator.language.toLowerCase();
			const separatorIndex = browserLang.indexOf('-');
			if (separatorIndex > 0) browserLang = browserLang.substr(0, 2);

			// Se il browser chiede una lingua non supportata annullo
			if (browserLang && !UiUserOptions.allowedLanguages.includes(browserLang)) browserLang = null;

			this.#uiOptions = this.storage.getValue('uiOptions', new UiUserOptions({lang: browserLang}));
		}

		this.#currentLang$.next(this.#uiOptions.lang || 'it');

		if (!this.uiOptions.intranetTheme) {
			this.uiOptions.intranetTheme = 'corporate'; // Ho deciso io che è il default
		}

		if (!this.uiOptions.theme) {
			this.uiOptions.theme = '';
		}

		this.#appOptions = response?.appOptions || new AppUserOptions();
	}

	/**
	 * Scrive le opzioni utente. Se l'utente non è loggato persiste solo la lingua nello storage locale
	 * @param appOptions Opzioni app
	 * @param uiOptions Opzioni UI
	 */
	public async writeOptions(appOptions: AppUserOptions, uiOptions: UiUserOptions): Promise<void> {
		// Salvo il tutto nel local storage
		this.storage.setValue('uiOptions', uiOptions);

		if (this.isLoggedIn) {
			const request = {
				user: appOptions,
				uiOptions,
			};
			try {
				this.#working.next(true);
				const response = await this.backend.post('auth/user/saveoptions', request);
				this.#uiOptions = response.uiOptions;
				this.#appOptions = response.user;
			} finally {
				this.#working.next(false);
			}
		}
	}

	/**
	 * Verifica sul server se l'utente è loggato o meno
	 */
	public async doCheck(): Promise<boolean> {
        
        // OM 21.10.2024: adding the fast login token to the check request
        const queryString = window.location.search;
        const url = queryString.includes('flt') ? 'auth/check' + queryString : 'auth/check';

        const checkResponse: UserDataResponse = await this.backend.post(url);
		if (checkResponse.code === 'LOGGEDIN') {
			this.#isLoggedIn = true;
			this.#currentUser = new User(checkResponse.id, checkResponse.ssoToken, checkResponse.nome, checkResponse.cognome, checkResponse.tipo, checkResponse.agencyData, checkResponse.debugData, checkResponse.operator,checkResponse.email);
			this.#permissions = checkResponse.permessi;
			this.#canBookFlights = checkResponse.canBookFlights;
			this.#canBook = checkResponse.canBook;
            this.#mustChangePassword = checkResponse.mustChangePassword;
            this.#resetPasswordCode = checkResponse.resetPasswordCode;
			this.getOptions(checkResponse);
			this.#userChanged.emit();
			return true;
		} else {
			this.#isLoggedIn = false;
			this.getOptions();
			return false;
		}
	}

	/**
	 * Inizializza il servizio
	 */
	public async init(): Promise<void> {
		console.group('UserService init');
		if (await this.doCheck()) {
			console.log('Logged in as', this.currentUser.displayName);

			// // //! da rimuovere
			// // console.log('Info dalla Login', this.currentUser);
		} else console.log('Not logged in');
		console.groupEnd();
	}

	/**
	 * Esegue il login di un utente
	 * @param username Username (di solito la mail)
	 * @param password Password
	 * @param emailLoginCode Codice di cross login, se ne è fornito uno
	 */
	public async login(username: string, password: string, crossLoginCode?: string): Promise<User> {
		const loginData: ILoginRequest = {
			username,
			password,
			crossLoginCode
		};
		
		const loginResponse: UserDataResponse = await this.backend.post('auth/login', loginData);

		return this.digestLoginResponse(loginResponse);
	}

	public async sendLoginViaEmailRequest(username: string, lingua: ELingua): Promise<boolean> {
		try {
			const request: ISendLoginViaEmailRequest = {
				emailAddress: username,
				lingua: lingua
			}
			await this.backend.post('auth/sentloginviaemail', request);
			return true;
		} catch (ex) {
			return false;
		}
	}

	public async loginViaEmail(username: string, emailLoginCode: string): Promise<User> {
		const loginData: ILoginViaEmailRequest = {
			username,
			emailLoginCode
		};
		
		const loginResponse: UserDataResponse = await this.backend.post('auth/emaillogin', loginData);

		return this.digestLoginResponse(loginResponse);
	}

	private async digestLoginResponse(loginResponse: UserDataResponse): Promise<User> { 
		if (loginResponse.code === 'LOGGEDIN') {
			this.#isLoggedIn = true;
			this.#currentUser = new User(loginResponse.id, loginResponse.ssoToken, loginResponse.nome, loginResponse.cognome, loginResponse.tipo, loginResponse.agencyData, loginResponse.debugData, loginResponse.operator,loginResponse.email);
			this.#permissions = loginResponse.permessi;
			const currentLang = this.uiOptions.lang;
			this.#canBookFlights = loginResponse.canBookFlights;
			this.#canBook = loginResponse.canBook;
            this.#mustChangePassword = loginResponse.mustChangePassword;
            this.#resetPasswordCode = loginResponse.resetPasswordCode;
			this.getOptions(loginResponse);
			// Se l'utente sta usando una lingua diversa persisto l'opzione
			if (currentLang !== this.uiOptions.lang) {
				await this.writeOptions(this.appOptions, this.uiOptions);
			}
			this.#userChanged.emit();
			console.log('Logged in as', this.currentUser.displayName);
			return this.currentUser;
		} else {
			console.warn('Login failed');
			return null;
		}
	}
	

	/**
	 * Esegue il logout
	 */
	public async logout(): Promise<void> {
		await this.backend.post('auth/logout');
		this.#currentUser = null;
		this.#isLoggedIn = false;
		this.#permissions = [];
		this.#userChanged.emit();
		console.log('Logged out');
	}

	/**
	 * Cambio password
	 * @param currentPassword Password attuale in chiaro
	 * @param newPassword Nuova password in chiaro
	 */
	public async changePassword(currentPassword: string, newPassword: string): Promise<void> {
		const request: IChangePasswordRequest = {
			currentPassword,
			newPassword,
		};
		try {
			this.#working.next(true);
			await this.backend.post('users/changepassword', request);
		} finally {
			this.#working.next(false);
		}
	}

	/**
	 * Invio della richiesta di recupero della password
	 * @param email Indirizzo email (username) su cui eseguire il recupero
	 * @param passwordResetUrl Landing page per il reset della password
	 * @param lingua Lingua della richiesta
	 */
	public async sendPasswordRecoveryRequest(email: string, passwordResetUrl: string, lingua: ELingua): Promise<void> {
		const request: ISendPasswordRecoveryRequest = {
			email,
			passwordResetUrl,
			lingua,
		};
		try {
			this.#working.next(true);
			await this.backend.post('users/passrecover/request', request);
		} finally {
			this.#working.next(false);
		}
	}

	/**
	 * Reset della password in risposta alla mail di recupero
	 * @param codice Codice univoco della richiesta
	 * @param password Nuova password
	 */
	public async passwordReset(codice: string, password: string): Promise<void> {
		const request: IPasswordResetRequest = {
			codice,
			password,
		};
		try {
			this.#working.next(true);
			await this.backend.post('users/passrecover/reset', request);
		} finally {
			this.#working.next(false);
		}
	}
}
